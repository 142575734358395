import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Confetti from 'react-confetti';
import './Results.css';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '20px auto',
        maxWidth: '800px',
        padding: '20px',
        borderRadius: '4px',
        textAlign: 'left',
        animation: '$fadeIn 0.5s',
    },
    invalidContainer: {
        backgroundColor: '#ffeeba',
        color: '#856404',
        border: '1px solid #ffeeba',
        marginBottom: theme.spacing(4),
    },
    validContainer: {
        backgroundColor: '#d4edda',
        color: '#155724',
        border: '1px solid #c3e6cb',
    },
    table: {
        marginBottom: theme.spacing(2),
    },
    done: {
        textDecoration: 'line-through',
        color: '#888',
    },
    '@keyframes fadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    '@keyframes slideDown': {
        from: { opacity: 1, maxHeight: '100px' },
        to: { opacity: 0, maxHeight: '0' },
    },
    hiddenRow: {
        animation: '$slideDown 0.5s forwards',
    },
}));

function Results({ results, error }) {
    const classes = useStyles();
    const [invalidRecords, setInvalidRecords] = useState(results ? results.invalidRecords.map(record => ({ ...record, isDone: false })) : []);
    const [validRecords, setValidRecords] = useState(results ? Object.entries(results.brokerageByClient) : []);

    useEffect(() => {
        if (results) {
            setInvalidRecords(results.invalidRecords.map(record => ({ ...record, isDone: false })));
            setValidRecords(Object.entries(results.brokerageByClient));
            setTimeout(() => {
                document.querySelector('#confetti').style.display = 'none';
            }, 3000);
        }
    }, [results]);

    const handleCheckboxChange = (index) => {
        const updatedRecords = [...invalidRecords];
        updatedRecords[index].isDone = true;
        setInvalidRecords(updatedRecords.filter(record => !record.isDone).concat(updatedRecords.filter(record => record.isDone)));
    };

    if (error) {
        return (
            <div className={`${classes.container} error`}>
                <Typography variant="h4">Error</Typography>
                <p>{error.error}</p>
            </div>
        );
    }

    if (results) {
        const { totalRecords, invalidRecordsCount } = results;
        const totalBrokerage = validRecords.reduce((acc, [_, brokerage]) => acc + parseFloat(brokerage), 0);

        return (
            <div>
                <Confetti id="confetti" width={window.innerWidth} height={window.innerHeight} recycle={false} />
                <Typography variant="h4" gutterBottom>Validation Results</Typography>
                <Typography variant="body1">Trade Date: {results.tradeDate}</Typography>
                <Typography variant="body1">Total Records Processed: {totalRecords}</Typography>
                <Typography variant="body1">Total Invalid Records: {invalidRecordsCount}</Typography>

                {invalidRecordsCount > 0 && (
                    <TableContainer component={Paper} className={`${classes.container} ${classes.invalidContainer}`}>
                        <Typography variant="h5" gutterBottom>Invalid Records</Typography>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Client ID</TableCell>
                                    <TableCell align="right">Segment</TableCell>
                                    <TableCell align="right">Security Name</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invalidRecords.map((record, index) => (
                                    <TableRow key={index} className={record.isDone ? classes.done : ''}>
                                        <TableCell>{record.AccountID}</TableCell>
                                        <TableCell align="right">{record.Segment}</TableCell>
                                        <TableCell align="right">{record['Security Name']}</TableCell>
                                        <TableCell align="center">
                                            <Checkbox
                                                color="primary"
                                                checked={record.isDone}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <TableContainer component={Paper} className={`${classes.container} ${classes.validContainer}`}>
                    <Typography variant="h5" gutterBottom>Valid Records</Typography>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Client ID</TableCell>
                                <TableCell align="right">Brokerage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {validRecords.map(([clientId, brokerage], index) => (
                                <TableRow key={index} className={classes.validRow}>
                                    <TableCell>{clientId}</TableCell>
                                    <TableCell align="right">{parseFloat(brokerage).toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell component="th" scope="row"><strong>Total</strong></TableCell>
                                <TableCell align="right"><strong>{totalBrokerage.toFixed(2)}</strong></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }

    return null;
}

Results.propTypes = {
    results: PropTypes.object,
    error: PropTypes.object,
};

export default Results;
